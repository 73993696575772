<!--
 * @Author: FengHang
 * @LastEditors: FengHang
 * @Date: 2022-06-29 16:14:29
 * @LastEditTime: 2022-06-30 10:43:43
-->
<template>
	<div class="activityMy">
		<div class="activityMy-content">
			<el-button @click="actionFn('add')">新增共建基地</el-button>
			<div class="activityMy-table">
				<el-table :data="myData" border>
					<el-table-column width="200px" prop="id" label="基地ID">
					</el-table-column>
					<el-table-column prop="name" label="共建基地">
						<template slot-scope="scope">
							<div class="table-action">
								<span @click="actionFn('baseAc', scope)"
									class="table-action-href">{{scope.row.name}}</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column prop="organization" label="所属组织">
					</el-table-column>
					<el-table-column width="200px" prop="status" label="当前状态">
						<template slot-scope="scope">
							<span class="">{{scope.row.auditStatus == 0? "审核中":scope.row.auditStatus == 1? "已通过":scope.row.auditStatus == 2? "未通过":""}}</span>
						</template>
					</el-table-column>
					<el-table-column width="200px" prop="action" label="操作">
						<template slot-scope="scope">
							<div class="table-action">
                <span @click="actionFn('edit', scope)" style="margin-right:10px;" v-if="scope.row.auditStatus == 1">编辑</span>
								<span @click="actionFn('baseDetail', scope)">详情</span>
               
								<!-- <el-divider direction="vertical"></el-divider>
								<span :class="{'table-action-red': scope.row.state == 1}"
									@click="actionFn('open', scope)">{{scope.row.state == 0? "开启":scope.row.state == 1? "停用":""}}</span> -->
							</div>
						</template>
					</el-table-column>
				</el-table>
				<div class="common-pageNation">
					<el-pagination background @current-change="currentChange" :current-page.sync="pageIndex"
						:page-size="pageSize" layout="total, prev, pager, next" :total="totalCount">
					</el-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getMyBaseList, disableBase } from "@/api/activity";
export default {
  data() {
    return {
      myData: [],
      totalCount: 0,
      pageIndex: 1,
      pageSize: 10,
    };
  },
  mounted() {
    this.myBaseList();
  },
  methods: {
    actionFn(type, scope) {
      switch (type) {
        case "baseAc":
          if (scope.row.auditStatus == 0) {
            this.$message({
              type: "warning",
              message: "此共建基地正在审核中!"
            });
          }else if(scope.row.auditStatus == 2){
            this.$message({
              type: "warning",
              message: "此共建基地审核未通过!"
            });
          } else {
            this.$router.push({
              path: `/activityMy/detail?baseId=${scope.row.id}&&baseName=${
                scope.row.name
              }&&baseState=${scope.row.state}`
            });
          }
          break;
        case "open":
          if (scope.row.state == 0) {
            this.$confirm("确定开启此共建基地吗?", "开启共建基地", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                let params = {
                  id: scope.row.id,
                  state: 1
                };
                disableBase(scope.row.id, params).then(res => {
                  if (res.code == 0) {
                    scope.row.state = 1;
                    this.$message({
                      type: "success",
                      message: "开启成功!"
                    });
                    this.$forceUpdate();
                  }
                });
              })
              .catch(() => {});
          } else {
            this.$confirm("确定停用此共建基地吗?", "停用共建基地", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
              .then(() => {
                let params = {
                  id: scope.row.id,
                  state: 0
                };
                disableBase(scope.row.id, params).then(res => {
                  if (res.code == 0) {
                    scope.row.state = 0;
                    this.$message({
                      type: "success",
                      message: "停用成功!"
                    });
                    this.$forceUpdate();
                  }
                });
              })
              .catch(() => {});
          }
          break;
        case "add":
          this.$router.push({
            path: "/activityMy/addBase"
          });
          break;
        case "edit":
          this.$router.push({
            path: `/activityMy/editBase?baseId=${scope.row.id}`
          });
          break;
        case "baseDetail":
          this.$router.push({
            path: `/activityMy/baseDetail?baseId=${scope.row.id}`
          });
          break;
      }
    },
    currentChange(val) {
      this.pageIndex = val;
      this.myData = [];
      this.myBaseList();
    },
    myBaseList() {
      let params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      };
      getMyBaseList(params).then(res => {
        if (res.code == 0) {
          this.myData = res.data;
          this.totalCount = res.page.totalCount * 1;
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.activityMy {
  .activityMy-content {
    padding: 20px 20px;

    .activityMy-table {
      margin-top: 20px;

      .table-action-red {
        color: red !important;
      }

      .table-action-href {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 20px;
        color: #1cabb9;
        text-decoration: underline;
      }
    }
  }
}
</style>
